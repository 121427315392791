/**
* This file is loaded asyncronously in the footer.
* All custom global scripts should be included here.
*/

	/*
	A little info for this js file:

	As much as possible, this file should be kept organized. Currently, two major divisions are seen below, delinitated by their respective functions of "on load" and "ready".
		As mentioned below, most code added to this file should be included within the "on load" function.

	For ease of future use, include any new code at the bottom of the relevant section of the page, adding clear comments to explain the purpose of the code being written,
		including any relavant details regarding what the code overrides, etc.
	*/


(function(){
	'use strict';

	// Load event function ... most custom stuff goes here. Safer for async.
	window.addEventListener( 'load', function() {
		
		/**
		 * Linkclicker
		 * 
		 * A class placed on a wrapping element with a link inside to make the wrapper clickable.
		 * Note: the container should have only one link.
		 */
		var linkclickers = document.querySelectorAll('.linkclicker');

		for ( var i = 0; linkclickers.length > i; i++ ) {
			var linkclicker = linkclickers[i];

			linkclicker.addEventListener('click', function(e) {
				e.preventDefault();
				var link = this.querySelector('a');
				var linkPath = link.getAttribute('href');
				
				if ( link.getAttribute('target', "_blank") ) {
					window.open(linkPath);
				} else {
					window.location.href = linkPath;
				}
			});
		}


		/**
		 * Force external links/PDFs to open in a new tab.
		 * From https://stackoverflow.com/questions/2910946/test-if-links-are-external-with-jquery-javascript
		 * 2019-07-11 - TA
		 */
		function link_is_external(link_element) {
			return ( link_element.host !== window.location.host || link_element.href.indexOf("pdf") > -1 );
		}

		var anchorElements = document.getElementsByTagName('a');
		for ( let anchorElement of anchorElements ) {

			if ( link_is_external( anchorElement ) ) {
				anchorElement.setAttribute( 'target','_blank' );
				anchorElement.setAttribute( 'rel','noopener' );

				// Add assistive text to alert of external links
				var assistiveText = '<span class="assistive">. External Link. Opens in new window.</span>';
				var assistiveTextContainer = document.createElement( 'span' );
				assistiveTextContainer.innerHTML = assistiveText;
				anchorElement.appendChild( assistiveTextContainer.firstChild );
			}
		}

		/**
		 * Detects if element is in viewport
		 * 
		 * @usage
		 * var el = document.querySelector('.element-class');
		 *
		 * window.addEventListener('scroll', function(event) {
		 * 		if (isInViewport(el)) {
		 *			el.classList.add("in-view");
		 *		}
		 * }, false);
		 * 
		 * NOTE: If the whole layout is taller than the viewport, this will never fire. It is often
		 * better to target one small element inside a layout, or put a small hidden placeholder
		 * element high in the layout that can be a sibling to the wrapping element so it can be
		 * targeted.
		 */
		function isInViewport(elem) {
			var distance = elem.getBoundingClientRect();
			return (
				distance.top >= 0 &&
				distance.left >= 0 &&
				distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				distance.right <= (window.innerWidth || document.documentElement.clientWidth)
			);
		};

		var els = document.querySelectorAll('.animated');
		
		for (let i = 0; els.length > i; i++) {
			let _this = els[i];

			if ( isInViewport(_this) ) {
				_this.classList.add("in-view");
			}

			window.addEventListener('scroll', function(event) {
				if ( isInViewport(_this) ) {
					_this.classList.add("in-view");
				}
			}, false);
		}
		

		/**
		 * Collapsibles
		 */
		var collapsibleHeaders = document.querySelectorAll('.collapsible-row__header');

		if ( collapsibleHeaders ) {
			for ( var i = 0; collapsibleHeaders.length > i; i++ ) {
				var collapsibleHeader = collapsibleHeaders[i];

				collapsibleHeader.addEventListener('click', function() {
					this.classList.toggle('active');
					var textHeight = this.nextElementSibling.querySelector('.collapsible-row__content').clientHeight; // Get height of text within collapsible row
					var rowHeight = this.nextElementSibling.clientHeight; // Get height of row to see if it's expanded

					if ( rowHeight == 0 ) { // If collapsible content is collapsed...
						this.nextElementSibling.style.height = textHeight + 1 + "px";
					} else {
						this.nextElementSibling.style.height = 0 + "px";
					}

					if ( this.parentNode.classList.contains('expanded') ) {
						this.setAttribute('aria-expanded', 'true');
					} else {
						this.setAttribute('aria-expanded', 'false');
					}
				});
			}
		}

		var faqs = document.querySelectorAll('.faqs__item');

		faqs.forEach( function(faq) {
			var toggle = faq.querySelector('.faqs__item-toggle');
			var answer = faq.querySelector('.faqs__item-answer');
			var answerText = answer.querySelector('.faqs__item-answer-text');
			var answerHeight = answerText.offsetHeight;
	
			toggle.addEventListener('click', function() {
				answer.classList.toggle('active');
	
				if (answer.classList.contains('active')) {
					answer.style.height = answerHeight + 'px';
				} else {
					answer.style.height = '0px';
				}
			});
		});
		
		var expandButtons = document.querySelectorAll('.expand-all');
		for (var i = 0; i < expandButtons.length; i++) {
			var button = expandButtons[i];
			button.addEventListener('click', function() {
				var currentButton = this;
				var toggles = this.parentElement.parentElement.querySelectorAll('.faqs__item-toggle');
				for (var i = 0; i < toggles.length; i++) {
					toggles[i].classList.toggle('active');
				}
				
				var answers = this.parentElement.parentElement.querySelectorAll('.faqs__item-answer');
				for (var i = 0; i < answers.length; i++) {
					var answerText = answers[i].querySelector('.faqs__item-answer-text');
					var answerHeight = answerText.offsetHeight;
					answers[i].classList.toggle('active');
	
					if (!currentButton.classList.contains('active')) {
						answers[i].style.height = answerHeight + 'px';
						answers[i].classList.add('active');
					} else {
						answers[i].style.height = '0px';
						answers[i].classList.remove('active');
					}
				}
				this.classList.toggle('active');
				
			});
		}

		/**
		 * Captures Google Analytics Client ID and inputs into hidden form field
		 */
		var g_id = document.querySelector('.g-id input' );

		function fillClientId() {
			// Capture Client ID
			var clientId = document.cookie.match(/_ga=(.+?);/)[1].split('.').slice(-2).join(".");
			// Populate hidden form field with Client ID
			g_id.value = clientId;
		}

		// On pages with client ID form field and analytics is loaded, populate
		if ( g_id && Object.keys( window.google_tag_manager || [] ).filter( e => { return e.substring(0,2) == 'G-' } ).length > 0 ) {
			// Give GTM time to load
			setTimeout(function() {
				fillClientId();
			}, 5000);
		}

		/**
		 * Captures the user's page viewing history into localStorage that will eventually be accessed
		 * when the user submits a form.
		 */
		var history = localStorage.getItem('history');

		// If there is already a user history, keep adding to it
		if (history) {
			var currentPage = window.location.href;
			var appendedHistory = history + ' > ' + currentPage;
			localStorage.setItem('history', appendedHistory);

			var userPathField = document.querySelector('.user-path input' );
			
			if ( userPathField ) {
				userPathField.value = appendedHistory;
				var form = userPathField.closest('form');
				// Local storage gets deleted in analytics form event below
			}
		} // If not, create the cookie and add the first page
		else {
			var title = window.location.href;
			localStorage.setItem('history', title);
		}

		/**
		 * Form completion event for custom event trigger/goal completion
		 * 
		 * The point of this method is to eliminate false conversions in Analytics
		 * due to users refreshing the /thank-you/ page. This will now set a cookie after a form 
		 * submit event and get the form's id to pass into analytics. The analytics event for a goal
		 * conversion is then generated if there a form completion cookie set, and the url is that
		 * of a thank-you page. Each form will have a unique conversion event in Analytics based on
		 * the formID that gets passed as the event label.
		 * 
		 * There is the assumption that all forms on the site are a Gravity Form, and that
		 * they re-direct to a /thank-you/ page of some kind on completion.
		 */
		var form = document.querySelector('.freeform-form-container form');

		if (form) {
			console.log('has-form');
			form.addEventListener('submit', function () {
				var formClass = form.getAttribute( 'class' );
				var formId = '';
				// Set ID to match old site analytics convention
				// Is this weird? Maybe.
				if ( formClass == 'contact-form' ) {
					formId = 'gform_1';
				} else if ( formClass == 'builder-program' ) {
					formId = 'gform_3';
				} else if ( formClass == 'builder-form' ) {
					formId = 'quote-builder-form';
				}

				setCookie( 'form-completion', 'form-completion-value', 1 );
				setCookie( 'form-id', formId, 1 );
			});
		}

		var formCompleted = readCookie( 'form-completion' );
		var formId = readCookie( 'form-id' );
		var url = window.location.href.indexOf( 'thank' ) >= 0; // Not exactly thank-you because the 
																// page titles/urls vary, but all contain 'thank'

		if ( formCompleted == 'form-completion-value' && url ) {
			// Create analytics event
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "formSubmission",
				formID: formId
			}); 

			// Remove cookies so event cannot be fired again on /thank-you page refresh
			eraseCookie( 'form-completion');
			eraseCookie( 'form-id');
			localStorage.removeItem('history');
		}

		document.querySelectorAll('.services__list-item').forEach(function(item) {
			item.addEventListener('click', function() {
				var index = Array.from(this.parentNode.children).indexOf(this);
				var parent = this.closest('.services__main');
				
				// Remove 'active' class from previously active item
				var activeItem = parent.querySelector('.services__list-item.active');
				if (activeItem) {
					activeItem.classList.remove('active');
				}
				this.classList.add('active');
		
				// Remove 'active' class from previously active description
				var activeDescription = parent.querySelector('.services__description.active');
				if (activeDescription) {
					activeDescription.classList.remove('active');
				}
				
				// Add 'active' class to the corresponding description
				var description = parent.querySelectorAll('.services__description')[index];
				if (description) {
					description.classList.add('active');
				}
			});
		});		
	}); // End of load function
	

	// Include ready events here
	window.addEventListener( 'DOMContentLoaded', function() {
		
	});
})();
